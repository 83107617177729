<template>
  <div class="card card-flush card-stretch gutter-b  shadow-sm">
    <b-alert
      show
      variant="info"
      class="alert alert-custom alert-danger alert-shadow fade show gutter-b"
      v-if="showError1"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-white svg-icon-lg">
          <inline-svg src="/media/svg/icons/Home/Timer.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>Kesalahan Pemilihan tanggal</b>
        Pemilihan tanggal awal dan akhir hanya dapat dilakukan pada bulan yang
        sama.
      </div>
    </b-alert>
    <b-alert
      show
      variant="info"
      class="alert alert-custom alert-danger alert-shadow fade show gutter-b"
      v-if="showError2"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-white svg-icon-lg">
          <inline-svg src="/media/svg/icons/Home/Timer.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>Kesalahan Pemilihan tanggal</b>
        Tanggal akhir harus lebih besar dari tanggal awal.
      </div>
    </b-alert>
    <div class="card-header border-0 pt-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">
            {{ $t("CONSULTATION_FILTER.FILTER_TITLE") }}
          </span>
          <!-- <span class="text-muted mt-3 font-weight-bold font-size-sm"> </span> -->
        </h3>
      </div>
    <div class="card-body pt-3 pb-0">
      <h5>{{ $t("CONSULTATION_FILTER.PERIOD") }}</h5>
      <div class="form-group">
        <b-form-select
            :options="years"
            v-model="selectedYear"
            class="mb-3"
            value-field="yearId"
            text-field="yearName"
            disabled-field="notEnabled"
            aria-placeholder="Tahun"
        >
          <template #first>
            <b-form-select-option :value="null">{{
                $t("CONSULTATION_FILTER.SELECT_YEAR")
              }}</b-form-select-option>
          </template>
        </b-form-select>
      </div>
      <div class="form-group">
        <b-form-select
          :options="months"
          v-model="selectedMonth"
          @change="countrySelect"
          class="mb-3"
          value-field="monthId"
          text-field="monthName"
          disabled-field="notEnabled"
          aria-placeholder="Bulan"
        >
          <template #first>
            <b-form-select-option :value="null">{{
              $t("CONSULTATION_FILTER.SELECT_MONTH")
            }}</b-form-select-option>
          </template>
        </b-form-select>
      </div>

      <h5>{{ $t("CONSULTATION_FILTER.LOCATION") }}</h5>
      <div class="form-group">
        <b-form-select
          :options="countries"
          v-model="selectedCountry"
          @change="countrySelect"
          class="mb-3"
          value-field="id"
          text-field="name"
          disabled-field="notEnabled"
          aria-placeholder="Negara"
        >
          <template #first>
            <b-form-select-option :value="null">{{
              $t("CONSULTATION_FILTER.SELECT_COUNTRY")
            }}</b-form-select-option>
          </template>
        </b-form-select>
      </div>
      <div class="form-group">
        <b-form-select
          v-model="selectedProvince"
          :options="provinces"
          @change="provinceSelect"
          class="mb-3"
          value-field="id"
          text-field="name"
          disabled-field="notEnabled"
        >
          <template #first>
            <b-form-select-option :value="null">{{
              $t("CONSULTATION_FILTER.SELECT_PROVINCE")
            }}</b-form-select-option>
          </template>
        </b-form-select>
      </div>
      <h5>{{ $t("CONSULTATION_FILTER.MEDIA") }}</h5>
      <div class="form-group">
        <b-form-select
          v-model="selectedMedia"
          :options="medias"
          @change="mediaSelect"
          class="mb-3"
          value-field="value"
          text-field="description"
          disabled-field="notEnabled"
        >
          <template #first>
            <b-form-select-option :value="null">{{
              $t("CONSULTATION_FILTER.SELECT")
            }}</b-form-select-option>
          </template>
        </b-form-select>
      </div>
      <h5>{{ $t("CONSULTATION_FILTER.TEMA") }}</h5>
      <div class="form-group">
        <b-form-select
          v-model="selectedTheme"
          :options="themes"
          @change="themeSelect"
          class="mb-3"
          value-field="theme_id"
          text-field="theme_name"
          disabled-field="notEnabled"
        >
          <template #first>
            <b-form-select-option :value="null">{{
              $t("CONSULTATION_FILTER.SELECT")
            }}</b-form-select-option>
          </template>
        </b-form-select>
      </div>
      <h5>{{ $t("CONSULTATION_FILTER.ISU") }}</h5>
      <div class="form-group">
        <b-form-select
            v-model="selectedIssue"
            :options="issues"
            @change="issueSelect"
            class="mb-3"
            value-field="issue_id"
            text-field="issue_name"
            disabled-field="notEnabled"
        >
          <template #first>
            <b-form-select-option :value="null">{{
              $t("CONSULTATION_FILTER.SELECT")
            }}</b-form-select-option>
          </template>
        </b-form-select>
      </div>
      <div class="form-group row align-center">
        <div class="col-6 text-center">
          <button
            type="button"
            @click="filterDashboard"
            class="btn btn-light-primary font-weight-bold"
          >
            <i class="icon-xl la la-filter"></i> Filter
          </button>
        </div>
        <div class="col-6 text-center">
          <button
            @click="resetFilter"
            type="button"
            class="btn btn-light-danger font-weight-bold"
          >
            <i class="icon-xl la la-history"></i>Reset
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ADD_COUNTRY,
  ADD_PROVINCE,
  SET_COUNTRY,
  SET_COUNTRY_SELECTED
} from "@/core/services/store/province.module";
import {
  GET_ISSUE_DATA_LIST,
  SET_ISSUE_DATA_LIST,
  SET_ISSUE_SELECTED,
  GET_THEME_DATA_LIST,
  SET_THEME_DATA_LIST,
  SET_THEME_SELECTED,
  GET_MEDIA_DATA_LIST,
  SET_MEDIA_DATA_LIST,
  SET_MEDIA_SELECTED,
} from "@/core/services/store/consultation-filter.module";
import {
  RESET_FILTER_CONSULTATION,
  SET_FILTER_CONSULTATION
} from "@/core/services/store/consultation.module";
import {SET_CONSULTATION_LOADING} from "@/core/services/store/loading.module";

export default {
  name: "ConsultationFilter",
  components: {},
  data() {
    return {
      locale: localStorage.getItem("language") || "id",
      month: null,
      year: null,
      validateDate: null,
      selectedCountry: null,
      selectedProvince: null,
      selectedIssue: null,
      selectedTheme: null,
      selectedMedia: null,
      provinces: [],
      countries: [],
      issues: [],
      themes: [],
      medias: [],
      months: [],
      years: [],
      minDate: new Date().setDate(1).toString(),
      maxDate: new Date().setDate(10).toString(),
      showError1: false,
      showError2: false,
      selectedMonth: null,
      selectedYear: null
    };
  },
  mounted() {
    this.$store.dispatch(SET_COUNTRY);
    this.$store.dispatch(GET_ISSUE_DATA_LIST);
    this.$store.dispatch(GET_THEME_DATA_LIST);
    this.$store.dispatch(GET_MEDIA_DATA_LIST);
    this.calculateMonths();
    this.calculateYear();
    this.bindingData();
  },
  methods: {
    bindingData() {
      this.unsubscribe = this.$store.subscribe(mutation => {
        if (mutation.type === ADD_PROVINCE) {
          this.provinces = this.getProvinces;
        }
        if (mutation.type === ADD_COUNTRY) {
          this.countries = this.getCountries;
        }
        if (mutation.type === SET_ISSUE_DATA_LIST) {
          this.issues = this.getConsultIssueData;
        }
        if (mutation.type === SET_THEME_DATA_LIST) {
          this.themes = this.getConsultThemeData;
        }
        if (mutation.type === SET_MEDIA_DATA_LIST) {
          this.medias = this.getConsultMediaData;
        }
      });
    },
    countrySelect(val) {
      this.$store.dispatch(SET_COUNTRY_SELECTED, val);
    },
    provinceSelect() {
      // console.log(val);
      // this.$store.dispatch(SET_SELECTED_PROVINCE, val);
    },
    issueSelect(val) {
      this.$store.dispatch(SET_ISSUE_SELECTED, val);
    },
    themeSelect(val) {
      this.$store.dispatch(SET_THEME_SELECTED, val);
    },
    mediaSelect(val) {
      this.$store.dispatch(SET_MEDIA_SELECTED, val);
    },
    filterDashboard() {
      this.$store.dispatch(SET_CONSULTATION_LOADING, true);
      this.month = this.selectedMonth ?? null;
      this.year = this.selectedYear ?? null;
      let payload = {
        month: this.month,
        year: this.year,
        province: this.selectedProvince ?? null,
        country: this.selectedCountry ?? null,
        issue: this.selectedIssue ?? null,
        media: this.selectedMedia ?? null,
        theme: this.selectedTheme ?? null
      };
      // console.log(payload);
      this.$store.dispatch(SET_FILTER_CONSULTATION, payload);
      this.$store.dispatch(SET_CONSULTATION_LOADING, false);
    },
    resetFilter() {
      this.$store.dispatch(SET_CONSULTATION_LOADING, true);
      this.month = null;
      this.year = null;
      this.selectedMonth = null;
      this.selectedYear = null;
      this.selectedProvince = null;
      this.selectedCountry = null;
      this.selectedTheme = null;
      this.selectedIssue = null;
      this.selectedMedia = null;
      let payload = {
        month: this.month,
        year: this.year,
        province: this.selectedProvince,
        country: this.selectedCountry,
        issue: this.selectedIssue,
        theme: this.selectedTheme,
        media: this.selectedMedia,
      };
      // console.log(payload);
      this.$store.dispatch(RESET_FILTER_CONSULTATION, payload);
      this.$store.dispatch(SET_CONSULTATION_LOADING, false);
    },
    monthSelected(data) {
      this.month = data.monthIndex;
      this.year = data.year;
    },
    calculateMonths() {
      for (let i = 1; i <= 12; i++) {
        let locale = localStorage.getItem("language") || "id";
        let year = this.selectedYear || new Date().getFullYear();
        let date = new Date(year, i - 1, 1);
        this.months.push({
          monthId: i,
          monthName: date.toLocaleString(locale, { month: "long" })
        });
      }
    },
    calculateYear() {
      let yearNow = new Date().getFullYear();
      for (let i = yearNow - 3; i <= yearNow; i++) {
        if (i >= 2022)
          this.years.push({
            yearName: i,
            yearId: i
          });
      }
    }
  },
  computed: mapGetters([
    "getProvinces",
    "getCountries",
    "getConsultIssueData",
    "getConsultThemeData",
    "getConsultMediaData"
  ])
};
</script>
<style>
.month-picker__container {
  background: white;
}
.month-picker-input {
  width: 250px;
  font-size: 16px;
}
</style>
