<template>
  <div>
    <Loading
      :active.sync="this.isLoading"
      :is-full-page="true"
      :can-cancel="true"
    ></Loading>
    <b-alert
        show
        variant="info"
        class="alert alert-custom alert-dark alert-shadow fade show gutter-b"
    >
      <div class="alert-text">
        <h4><b> Total : {{ this.total_case }} Konsultasi</b></h4>
      </div>
    </b-alert>
    <div class="row" v-if="this.isAuthenticated">
      <div class="col-xxl-9">
        <div class="row">
          <ConsultationMedia
            class="col-xxl-6 order-1 order-xxl-1"
            v-if="
          this.getConsultationDashboardCardStatus('klasifikasiKorban')
            ? this.getConsultationDashboardCardStatus('klasifikasiKorban').status
            : false
          "
          ></ConsultationMedia>
          <ConsultationTema
              class="col-xxl-6 order-2 order-xxl-2"
              v-if="
            this.getConsultationDashboardCardStatus('klasifikasiHak')
              ? this.getConsultationDashboardCardStatus('klasifikasiHak').status
              : false
          "
          ></ConsultationTema>
          <ConsultationIssue
              class="col-xxl-6 order-3 order-xxl-3"
              v-if="
            this.getConsultationDashboardCardStatus('klasifikasiKorban')
              ? this.getConsultationDashboardCardStatus('klasifikasiKorban').status
              : false
          "
          ></ConsultationIssue>
        </div>
      </div>
      <div class="col-xxl-3">
        <div class="row">
          <ConsultationFilter
              class="col-xxl-12 order-1 order-xxl-1"
              v-if="
                this.getConsultationDashboardCardStatus('MapSebaranWilayah')
                  ? this.getConsultationDashboardCardStatus('MapSebaranWilayah').status
                  : false
              "
          ></ConsultationFilter>
        </div>
      </div>
    </div>
    <div class="row" v-else >
      <div class="col-xxl-12">
        <div class="row">
          <ConsultationMedia
              class="col-xxl-4 order-1 order-xxl-1"
              v-if="
          this.getConsultationDashboardCardStatus('klasifikasiKorban')
            ? this.getConsultationDashboardCardStatus('klasifikasiKorban').status
            : false
          "
          ></ConsultationMedia>
          <ConsultationTema
              class="col-xxl-4 order-2 order-xxl-2"
              v-if="
            this.getConsultationDashboardCardStatus('klasifikasiHak')
              ? this.getConsultationDashboardCardStatus('klasifikasiHak').status
              : false
          "
          ></ConsultationTema>
          <ConsultationIssue
              class="col-xxl-4 order-3 order-xxl-3"
              v-if="
            this.getConsultationDashboardCardStatus('klasifikasiKorban')
              ? this.getConsultationDashboardCardStatus('klasifikasiKorban').status
              : false
          "
          ></ConsultationIssue>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.custom-control-label {
  color: white !important;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_ISSUE_CASE,
  GET_THEME_CASE,
  GET_MEDIA_CASE,
  GET_CONSULTATION_TOTAL_CASE,
  SET_CONSULTATION_TOTAL_CASE, GET_DASHBOARD_DATA
} from "@/core/services/store/consultation.module";
import { mapGetters } from "vuex";
import ConsultationMedia from "@/view/content/widgets/list/ConsultationMedia";
import ConsultationIssue from "@/view/content/widgets/list/ConsultationIssue";
import ConsultationTema from "@/view/content/widgets/list/ConsultationTema";
import ConsultationFilter from "@/view/content/widgets/ConsultationFilter";
// import * as axios from "axios";
// import Swal from 'sweetalert2'
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {SET_CONSULTATION_LOADING} from "@/core/services/store/loading.module";

export default {
  name: "dashboard",
  components: {
    ConsultationFilter,
    ConsultationTema,
    ConsultationIssue,
    ConsultationMedia,
    Loading,
  },
  data() {
    return {
      latestUpdate: Date.now(),
      bodyBgVariant: "dark",
      footerTextVariant: "dark",
      error_surver: "",
      total_case: 0,
      show_alert: false,
      isLoading: false,
    };
  },
  beforeMount() {
    this.$store.dispatch(SET_CONSULTATION_LOADING, true);
    this.$store.dispatch(GET_CONSULTATION_TOTAL_CASE);
    this.$store.dispatch(GET_ISSUE_CASE);
    this.$store.dispatch(GET_THEME_CASE);
    this.$store.dispatch(GET_MEDIA_CASE);
    this.$store.dispatch(GET_DASHBOARD_DATA);
  },
  mounted() {
    this.total_case = this.getConsultationTotalCase;
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === SET_CONSULTATION_TOTAL_CASE){
          this.total_case = this.getConsultationTotalCase;
      }
      if (mutation.type === SET_CONSULTATION_LOADING) {
        this.isLoading = this.getLoadingConsultationState;
      }
    });
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.COMPLAINT_DATA"), route: "dashboard" },
      { title: this.$t("MENU.COMPLAINT_CONSULTATION") }
    ]);
  },
  methods: {
    showFilter(){
      this.$refs["my-modal"].show();
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "getConsultationDashboardCardStatus",
      "getConsultationSelectedYear",
      "getConsultationSelectedMonth",
      "getConsultationSelectedProvince",
      "getConsultationTotalCase",
      "isAuthenticated",
      "getConsultationPostParams",
      "getLoadingConsultationState",
    ]),
  },
};
</script>
