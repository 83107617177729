var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Loading',{attrs:{"active":this.isLoading,"is-full-page":true,"can-cancel":true},on:{"update:active":function($event){return _vm.$set(this, "isLoading", $event)}}}),_c('b-alert',{staticClass:"alert alert-custom alert-dark alert-shadow fade show gutter-b",attrs:{"show":"","variant":"info"}},[_c('div',{staticClass:"alert-text"},[_c('h4',[_c('b',[_vm._v(" Total : "+_vm._s(this.total_case)+" Konsultasi")])])])]),(this.isAuthenticated)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xxl-9"},[_c('div',{staticClass:"row"},[(
        this.getConsultationDashboardCardStatus('klasifikasiKorban')
          ? this.getConsultationDashboardCardStatus('klasifikasiKorban').status
          : false
        )?_c('ConsultationMedia',{staticClass:"col-xxl-6 order-1 order-xxl-1"}):_vm._e(),(
          this.getConsultationDashboardCardStatus('klasifikasiHak')
            ? this.getConsultationDashboardCardStatus('klasifikasiHak').status
            : false
        )?_c('ConsultationTema',{staticClass:"col-xxl-6 order-2 order-xxl-2"}):_vm._e(),(
          this.getConsultationDashboardCardStatus('klasifikasiKorban')
            ? this.getConsultationDashboardCardStatus('klasifikasiKorban').status
            : false
        )?_c('ConsultationIssue',{staticClass:"col-xxl-6 order-3 order-xxl-3"}):_vm._e()],1)]),_c('div',{staticClass:"col-xxl-3"},[_c('div',{staticClass:"row"},[(
              this.getConsultationDashboardCardStatus('MapSebaranWilayah')
                ? this.getConsultationDashboardCardStatus('MapSebaranWilayah').status
                : false
            )?_c('ConsultationFilter',{staticClass:"col-xxl-12 order-1 order-xxl-1"}):_vm._e()],1)])]):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xxl-12"},[_c('div',{staticClass:"row"},[(
        this.getConsultationDashboardCardStatus('klasifikasiKorban')
          ? this.getConsultationDashboardCardStatus('klasifikasiKorban').status
          : false
        )?_c('ConsultationMedia',{staticClass:"col-xxl-4 order-1 order-xxl-1"}):_vm._e(),(
          this.getConsultationDashboardCardStatus('klasifikasiHak')
            ? this.getConsultationDashboardCardStatus('klasifikasiHak').status
            : false
        )?_c('ConsultationTema',{staticClass:"col-xxl-4 order-2 order-xxl-2"}):_vm._e(),(
          this.getConsultationDashboardCardStatus('klasifikasiKorban')
            ? this.getConsultationDashboardCardStatus('klasifikasiKorban').status
            : false
        )?_c('ConsultationIssue',{staticClass:"col-xxl-4 order-3 order-xxl-3"}):_vm._e()],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }